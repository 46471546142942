<template>
  <div>
    <div class="chat_list">
      <div class="chat_item">
        <img src="@/assets/ava_default.png" />
        <div class="chat_content">您好，我是您的助理，欢迎来到心理测评。</div>
      </div>
      <div class="chat_item">
        <img src="@/assets/ava_default.png" />
        <div class="chat_content" style="line-height: 1.5">
          感谢您的配合，心理测评还需要完成以下步骤，为了测评的顺利进行，请您仔细阅读步骤!
        </div>
      </div>
      <div class="chat_item" style="align-items: flex-start">
        <img src="@/assets/ava_default.png" />
        <div>
          <img src="@/assets/step_info.png" />
        </div>
      </div>
    </div>
    <div class="know" @click="know">知道了</div>
  </div>
</template>

<script>
export default {
  name: "Step",
  methods: {
    know() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.chat_list {
  padding: 34px;
}
.chat_item {
  display: flex;
  align-items: center;
  margin: 12px 0px;
}
.chat_content {
  width: 624px;
  height: 96px;
  padding-right: 29px;
  padding-left: 45px;
  margin-left: 12px;
  font-size: 30px;
  font-weight: 400;
  line-height: 96px;
  color: #606266;
  background: #ffffff;
  border: 1px solid rgba(221, 221, 221, 0.91);
  border-radius: 53px 48px 48px 0px;
  box-shadow: 0px 0px 0px 0px rgba(184, 184, 184, 0.1);
}
.know {
  width: 728px;
  height: 102px;
  margin: 0px auto;
  margin-top: 130px;
  font-size: 36px;
  font-weight: 400;
  line-height: 102px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #078599;
  border-radius: 51px;
}
</style>
